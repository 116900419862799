import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ca rekey`}</strong>{` -- rekey a certificate`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ca rekey <crt-file> <key-file>
[--out-cert=<file>] [--out-key=<file>] [--private-key=<file>]
[--ca-url=<uri>] [--root=<file>] [--password-file=<file>]
[--expires-in=<duration>] [--force] [--exec=<string>] [--daemon]
[--kty=<type>] [--curve=<curve>] [--size=<size>]
[--expires-in=<duration>] [--pid=<int>] [--pid-file=<file>]
[--signal=<int>] [--exec=<string>] [--rekey-period=<duration>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ca rekey`}</strong>{` command rekeys the given certificate (with a request to the
certificate authority) and writes the new certificate and private key
to disk - either overwriting `}<inlineCode parentName="p">{`crt-file`}</inlineCode>{` and `}<inlineCode parentName="p">{`key-file`}</inlineCode>{` positional arguments
or using new files when the `}<strong parentName="p">{`--out-cert`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{` and `}<strong parentName="p">{`--out-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
flags are used.`}</p>
    <p>{`With the `}<strong parentName="p">{`--daemon`}</strong>{` flag the command will periodically update the given
certificate. By default, it will rekey the certificate before 2/3 of the validity
period of the certificate has elapsed. A random jitter is used to avoid multiple
instances running at the same time. The amount of time between rekey and
certificate expiration can be configured using the `}<strong parentName="p">{`--expires-in`}</strong>{` flag, or a
fixed period can be set with the `}<strong parentName="p">{`--rekey-period`}</strong>{` flag.`}</p>
    <p>{`The `}<strong parentName="p">{`--daemon`}</strong>{` flag can be combined with `}<strong parentName="p">{`--pid`}</strong>{`, `}<strong parentName="p">{`--signal`}</strong>{`, or `}<strong parentName="p">{`--exec`}</strong>{`
to provide certificate reloads on your services.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`crt-file`}</inlineCode>{`
The certificate in PEM format that we want to rekey.`}</p>
    <p><inlineCode parentName="p">{`key-file`}</inlineCode>{`
They key file of the certificate.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--out-cert`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` where the new certificate will be saved to.
Defaults to overwriting the `}<inlineCode parentName="p">{`crt-file`}</inlineCode>{` positional argument.`}</p>
    <p><strong parentName="p">{`--out-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` to store the new private key.
Defaults to overwriting the `}<inlineCode parentName="p">{`key-file`}</inlineCode>{` positional argument.`}</p>
    <p><strong parentName="p">{`--private-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the private key for rekey-ing the certificate.
By default, a new random key pair will be generated.`}</p>
    <p><strong parentName="p">{`--expires-in`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The amount of time remaining before certificate expiration,
at which point a rekey should be attempted. The certificate rekey will not
be performed if the time to expiration is greater than the `}<strong parentName="p">{`--expires-in`}</strong>{` value.
A random jitter (duration/20) will be added to avoid multiple services hitting the
rekey endpoint at the same time. The `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is a sequence of decimal numbers,
each with optional fraction and a unit suffix, such as "300ms", "-1.5h" or "2h45m".
Valid time units are "ns", "us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--pid`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
The process id to signal after the certificate has been rekeyed. By default the
the SIGHUP (1) signal will be used, but this can be configured with the `}<strong parentName="p">{`--signal`}</strong>{`
flag.`}</p>
    <p><strong parentName="p">{`--pid-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` from which to read the process id that will be signaled after the certificate
has been rekeyed. By default the SIGHUP (1) signal will be used, but this can be configured with the `}<strong parentName="p">{`--signal`}</strong>{`
flag.`}</p>
    <p><strong parentName="p">{`--signal`}</strong>{`=`}<inlineCode parentName="p">{`number`}</inlineCode>{`
The signal `}<inlineCode parentName="p">{`number`}</inlineCode>{` to send to the selected PID, so it can reload the
configuration and load the new certificate. Default value is SIGHUP (1)`}</p>
    <p><strong parentName="p">{`--exec`}</strong>{`=`}<inlineCode parentName="p">{`command`}</inlineCode>{`
The `}<inlineCode parentName="p">{`command`}</inlineCode>{` to run after the certificate has been rekeyed.`}</p>
    <p><strong parentName="p">{`--daemon`}</strong>{`
Run the rekey command as a daemon, rekeying and overwriting the certificate
periodically. By default the daemon will rekey a certificate before 2/3 of the
time to expiration has elapsed. The period can be configured using the
`}<strong parentName="p">{`--rekey-period`}</strong>{` or `}<strong parentName="p">{`--expires-in`}</strong>{` flags.`}</p>
    <p><strong parentName="p">{`--rekey-period`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The period with which to schedule rekeying of the certificate in daemon mode.
Requires the `}<strong parentName="p">{`--daemon`}</strong>{` flag. The `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is a sequence of decimal numbers,
each with optional fraction and a unit suffix, such as "300ms", "1.5h", or "2h45m".
Valid time units are "ns", "us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--kty`}</strong>{`=`}<inlineCode parentName="p">{`kty`}</inlineCode>{`
The `}<inlineCode parentName="p">{`kty`}</inlineCode>{` to build the certificate upon.
If unset, default is EC.`}</p>
    <p><inlineCode parentName="p">{`kty`}</inlineCode>{` is a case-sensitive string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`EC`}</strong>{`: Create an `}<strong parentName="p">{`elliptic curve`}</strong>{` keypair`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`OKP`}</strong>{`: Create an octet key pair (for `}<strong parentName="p">{`"Ed25519"`}</strong>{` curve)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RSA`}</strong>{`: Create an `}<strong parentName="p">{`RSA`}</strong>{` keypair`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--crv`}</strong>{`=`}<inlineCode parentName="p">{`curve`}</inlineCode>{`, `}<strong parentName="p">{`--curve`}</strong>{`=`}<inlineCode parentName="p">{`curve`}</inlineCode>{`
The elliptic `}<inlineCode parentName="p">{`curve`}</inlineCode>{` to use for EC and OKP key types. Corresponds
to the `}<strong parentName="p">{`"crv"`}</strong>{` JWK parameter. Valid curves are defined in JWA `}{`[RFC7518]`}{`. If
unset, default is P-256 for EC keys and Ed25519 for OKP keys.`}</p>
    <p><inlineCode parentName="p">{`curve`}</inlineCode>{` is a case-sensitive string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`P-256`}</strong>{`: NIST P-256 Curve`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`P-384`}</strong>{`: NIST P-384 Curve`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`P-521`}</strong>{`: NIST P-521 Curve`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Ed25519`}</strong>{`: Ed25519 Curve`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--size`}</strong>{`=`}<inlineCode parentName="p">{`size`}</inlineCode>{`
The `}<inlineCode parentName="p">{`size`}</inlineCode>{` (in bits) of the key for RSA and oct key types. RSA keys require a
minimum key size of 2048 bits. If unset, default is 2048 bits for RSA keys and 128 bits for oct keys.`}</p>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <p><strong parentName="p">{`--offline`}</strong>{`
Creates a certificate without contacting the certificate authority. Offline mode
uses the configuration, certificates, and keys created with `}<strong parentName="p">{`step ca init`}</strong>{`,
but can accept a different configuration file using `}<strong parentName="p">{`--ca-config`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to encrypt or decrypt the private key.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--ca-config`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The certificate authority configuration `}<inlineCode parentName="p">{`file`}</inlineCode>{`. Defaults to
$(step path)/config/ca.json`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Rekey a certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca rekey internal.crt internal.key
`}</code></pre>
    <p>{`Rekey a certificate without overwriting the existing certificate and key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca rekey --out-cert out.crt --out-key out.key internal.crt internal.key
`}</code></pre>
    <p>{`Rekey a certificate forcing the overwrite of the previous certificate and key
(overwrites the existing files without prompting):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca rekey --force internal.crt internal.key
`}</code></pre>
    <p>{`Rekey a certificate providing the `}<inlineCode parentName="p">{`--ca-url`}</inlineCode>{` and `}<inlineCode parentName="p">{`--root`}</inlineCode>{` flags:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca rekey --ca-url https://ca.smallstep.com:9000 \\
  --root /path/to/root_ca.crt internal.crt internal.key
Would you like to overwrite internal.crt [Y/n]: y
`}</code></pre>
    <p>{`Rekey a certificate only if it expires within the given time frame:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca rekey --expires-in 8h internal.crt internal.key
`}</code></pre>
    <p>{`Rekey the certificate before 2/3 of the validity has passed:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca rekey --daemon internal.crt internal.key
`}</code></pre>
    <p>{`Rekey the certificate before 8 hours and 30m of the expiration time:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca rekey --daemon --expires-in 8h30m internal.crt internal.key
`}</code></pre>
    <p>{`Rekey the certificate every 16h:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca rekey --daemon --rekey-period 16h internal.crt internal.key
`}</code></pre>
    <p>{`Rekey the certificate and reload nginx:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca rekey --daemon --exec "nginx -s reload" internal.crt internal.key
`}</code></pre>
    <p>{`Rekey the certificate and convert it to DER:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca rekey --daemon --rekey-period 16h \\
  --exec "step certificate format --force --out internal.der internal.crt" \\
  internal.crt internal.key
`}</code></pre>
    <p>{`Rekey a certificate using the offline mode, requires the configuration
files, certificates, and keys created with `}<strong parentName="p">{`step ca init`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca rekey --offline internal.crt internal.key
`}</code></pre>
    <p>{`Rekey the certificate and write it to specified files:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca rekey internal.crt internal.key --out-crt foo.crt --out-key foo.key
`}</code></pre>
    <p>{`Rekey the certificate using a given private key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca rekey internal.crt internal.key --private-key foo.key
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      